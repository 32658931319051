<template>
  <div class="MaintenanceWindows" v-if="monitor">
    <div class="form-wrapper">
      <maintenance-window-form :monitor="monitor"
                               @submitSuccess="onWindowAdd" />
    </div>
    <div class="list-wrapper">
      <maintenance-window-list ref="windowList"
                               :monitor="monitor" />
    </div>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'

import MaintenanceWindowForm from '@/components/Monitor//MaintenanceWindow/MonitorMaintenanceWindowForm.vue'
import MaintenanceWindowList from '@/components/Monitor/MaintenanceWindow/MonitorMaintenanceWindowList.vue'

export default {
  components: {
    MaintenanceWindowForm,
    MaintenanceWindowList
  },

  metaInfo: {
    title: 'Monitor Maintenance Windows'
  },

  props: {
    monitorId: {
      required: true
    }
  },

  data () {
    return {
      monitor: null
    }
  },

  async created () {
    this.monitor = await monitorsApi.find(this.monitorId)
  },

  methods: {
    onWindowAdd () {
      this.$refs.windowList.loadWindows()
    }
  }
}
</script>

<style lang="scss" scoped>
  .MaintenanceWindows {
    .form-wrapper {
      margin-bottom: 20px;
    }

    .list-wrapper {
      width: 600px;
    }
  }
</style>
