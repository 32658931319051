const baseURL = 'maintenance-windows'

export default {
  /**
   * Delete maintenance window.
   *
   * @param id
   * @returns Promise
   */
  delete (id) {
    return axios.delete(`${baseURL}/${id}`).then(response => response.data)
  }
}
