<template>
  <div class="MonitorMaintenanceWindowForm">
    <ValidationObserver ref="observer"
                        v-slot="{ invalid, passes }"
                        slim>
      <form class="form"
            @submit.prevent="passes(onFormSubmit)">
          <div class="start">
            <base-date-picker placeholder="Start Date"
                              :config="datePickerConfig"
                              v-model="window.starts_at" />
          </div>
          <div class="end ml-3">
            <base-date-picker placeholder="Finish Date"
                              :config="datePickerConfig"
                              v-model="window.ends_at" />
          </div>
          <div class="submit ml-3">
            <base-button type="submit"
                         color="primary"
                         class="button-submit"
                         :loading="saving">Add</base-button>
          </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'

export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      window: {
        starts_at: '',
        ends_at: ''
      },

      datePickerConfig: {
        enableTime: true,
        altInput: true,
        dateFormat: 'U',
        altFormat: 'd.m.Y H:i',

        // todo: doesn't work
        closeOnSelect: true
      },

      saving: false
    }
  },

  methods: {
    submit () {
      this.onFormSubmit()
    },

    async onFormSubmit () {
      this.saving = true

      const response = await monitorsApi.storeMaintenanceWindow(
        this.monitor.id,
        this.window
      )

      this.saving = false

      this.$emit('submitSuccess', response)
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorMaintenanceWindowForm {
    .form {
      display: flex;
      align-items: center;

      .start, .end, .submit {
        .base-date-picker {
          margin: 0;
        }

        flex: 1 0;
      }

      .button-submit {
        width: 150px;
      }
    }
  }
</style>
